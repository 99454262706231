<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.procedure_evidence.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Definición"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.procedure_evidence.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="row">
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";

export default {
  name: "ProcedureEvidenceForm",
  mixins: [validationMixin],
  props: {
    ProcedureEvidence: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          institution: "",
          active: true,
        };
      },
    },
    institution_id: {
      type: Number,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      procedure_evidence: this.ProcedureEvidence,
    };
  },
  validations: {
    procedure_evidence: {
      name: {
        required,
        minLength: minLength(5),
      },
      description: {
        required,
        minLength: minLength(5),
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.procedure_evidence[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.procedure_evidence.$touch();
      if (this.$v.procedure_evidence.$anyError) {
        return;
      }
      if (isNaN(this.procedure_evidence.id)) {
        this.createProcedureEvidence();
      } else this.updateProcedureEvidence();
    },
    createProcedureEvidence() {
      this.procedure_evidence.school = this.institution_id;
      this.$store
        .dispatch(
          names.POST_PROCEDURE_EVIDENCE,
          this.procedure_evidence,
          this.procedure_evidence.school
        )
        .then((response) => {
          toast("Evidencia de Desempeño creada.");
          this.$emit("created", response);
        });
    },
    updateProcedureEvidence() {
      this.procedure_evidence.school = this.institution_id;
      this.$store
        .dispatch(
          names.UPDATE_PROCEDURE_EVIDENCE,
          this.procedure_evidence,
          this.procedure_evidence.school
        )
        .then((response) => {
          toast("Evidencia de Desempeño actualizada.");
          this.$emit("updated", response);
        });
    },
  },
};
</script>

<style scoped>
</style>